/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
  /** primary **/

  --ion-color-primary: #1d508d;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #154d92;
  --ion-color-primary-tint: #1e569b;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}

@media (prefers-color-scheme: light) {
  /*
   * Dark Colors
   * -------------------------------------------
   */

  body {
    --ion-color-primary: #1d508d;
    --ion-color-primary-rgb: 66, 140, 255;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #3a7be0;
    --ion-color-primary-tint: #5598ff;

    --ion-color-secondary: #50c8ff;
    --ion-color-secondary-rgb: 80, 200, 255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #46b0e0;
    --ion-color-secondary-tint: #62ceff;

    --ion-color-tertiary: #6a64ff;
    --ion-color-tertiary-rgb: 106, 100, 255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #5d58e0;
    --ion-color-tertiary-tint: #7974ff;

    --ion-color-success: #2fdf75;
    --ion-color-success-rgb: 47, 223, 117;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0, 0, 0;
    --ion-color-success-shade: #29c467;
    --ion-color-success-tint: #44e283;

    --ion-color-warning: #ffd534;
    --ion-color-warning-rgb: 255, 213, 52;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0bb2e;
    --ion-color-warning-tint: #ffd948;

    --ion-color-danger: #ff4961;
    --ion-color-danger-rgb: 255, 73, 97;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #e04055;
    --ion-color-danger-tint: #ff5b71;

    --ion-color-light: #f4f5f8;
    --ion-color-light-rgb: 244, 245, 248;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #d7d8da;
    --ion-color-light-tint: #f5f6f9;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152, 154, 162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0, 0, 0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-dark: #222428;
    --ion-color-dark-rgb: 34, 36, 40;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #1e2023;
    --ion-color-dark-tint: #383a3e;
  }

  /*
   * iOS Dark Theme
   * -------------------------------------------
   */

  /* .ios body {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 0, 0, 0;
    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;
    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;
    --ion-item-background: #fff;
    --ion-card-background: #1c1c1d;
  } */

  .ios ion-modal {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
  }

  /*
   * Material Design Dark Theme
   * -------------------------------------------
   */

  /* .md body {
    --ion-background-color: #fff;
    --ion-background-color-rgb: 18, 18, 18;
    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;
    --ion-border-color: #222222;
    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;tlo
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;
    --ion-item-background: #fff;
    --ion-toolbar-background: #fff;
    --ion-tab-bar-background: #fff;
    --ion-card-background: #fff;
  } */
}

/* * {
  font-family: Nunito Sans light, sans-serif;
} */

.round-button {
  --border-radius: 50%;
  width: 36px;
  height: 36px;
}

#qr-scanner>section>section {
  height: 100vh !important;
}

#qr-scanner>section>section>div {
  border: none !important;
  box-shadow: none !important;
}

#qr-scanner>section>section>video {
  height: 100vh !important;
}

.alert-title.sc-ion-alert-md {
  color: rgba(0, 0, 0, 0.6);
}

/* .MuiSelect-iconOutlined {
  right: 15px !important;
} */

.formlcss {
  height: 8vh;
  width: 75vw;
  border-radius: 20px;
  border: 2px solid #dbe6ec;
  font-family: Nunito Sans light, sans-serif;
  padding-left: 13px;
  margin: auto;
  margin-top: 1rem;
}

.formsmlabel {
  font-family: Nunito Sans light, sans-serif;
  color: rgba(36, 54, 86, 1);
  margin-left: 2vw;
}

/* floating input */

.input-cont {
  position: relative;
  overflow: hidden;
}

.input-cont input {
  width: 100%;
  height: 100%;
  color: #111;
  opacity: 0.5;
  padding-top: 20px;
  border: none;
  outline: none;
  background: transparent;
}

.input-cont label {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.content-name {
  position: absolute;
  left: 0;
  transition: all 0.3s ease;
  margin-top: 2.5vh;
  font-size: 2vh;
}

.input-cont input:focus+.label-name .content-name,
.input-cont input:valid+.label-name .content-name {
  transform: translateY(-70%);
  font-size: 1.8vh;
  color: rgb(49, 114, 184);
}

.input-cont input:focus+.label-name::after,
.input-cont input:valid+.label-name::after {
  transform: translateX(0%);
}

/*--------------*/

/* .PrivateNotchedOutline-root-1,
.MuiOutlinedInput-notchedOutline {
  left: 12%;
  border-radius: 20px !important;
  border: 2px solid #dbe6ec !important;
  margin: 5px !important;
  padding-left: 13px !important;
} */

/* .custom .MuiInputBase-input {
  font-size: smaller !important;
  font-family: Inter !important;
  font-style: normal !important;
} */

/* .MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(15px, -3px) scale(0.95) !important;
} */

/* .MuiFormControl-root {
  width: 78%;
  font-family: Nunito Sans light, sans-serif !important;
} */

/* .MuiInputBase-root {
  color: rgba(29, 80, 141, 1) !important; */
/* padding-left: 15px; */
/* } */
/* 
.MuiFormLabel-root {
  opacity: 0.8;
  margin-left: 2vw !important;
  font-size: smaller !important;
  margin-top: 0.5vh;
} */

/* .MuiInputBase-input {
  text-align: left;
}

.logininputname .MuiInputLabel-outlined {
  margin-left: 22vw !important;
}

.logininputname .MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(-15.2vw, -3px) scale(0.95) !important;
}

.logininputpass .MuiInputLabel-outlined {
  margin-left: 22vw !important;
} */

/* .logininputpass .MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(-15.5vw, -3px) scale(0.95) !important;
}

.smallSelecter .MuiInputBase-root {
  width: 81%;
  height: 8vh;
} */

/* width: 95% !important; */
/* height: 8vh; */

/* .MuiSelect-outlined.MuiSelect-outlined {
  margin-left: -1vw;
} */

/* .dateinput .MuiInputLabel-outlined {
  transform: translate(15px, -3px) scale(0.95) !important;
  background-color: #fff;
  padding: 0 6px 0 3px;
}

.dateinput .PrivateNotchedOutline-legendLabelled-3 {
  max-width: 1000px;
  transition: max-width 100ms cubic-bezier(0, 0, 0.2, 1) 50ms;
} */

.formsmcss {
  height: 8vh;
  width: 37vw;
  border-radius: 20px;
  border: 2px solid #dbe6ec;
  margin: 5px;
  padding-left: 13px;
}

.onclick:active {
  opacity: 0.8;
}

ion-router-link:active {
  opacity: 0.8;
}

.formcssdrop {
  height: 8vh;
  width: 75vw;
  border-radius: 20px;
  border: 2px solid #dbe6ec;
  padding-left: 2vw;
  margin: auto;
  text-align: left;
  font-size: revert;
  margin-top: 1rem;
}

.formcssdrop::part(placeholder) {
  opacity: 0.5;
  color: rgba(36, 54, 86, 1);
  font-size: 2vh;
}

.no-outline:focus {
  outline: none;
}

.formcssdrop::part(text) {
  color: rgba(36, 54, 86, 1);
  font-size: 2.4vh;
  padding-left: 15px;
}


.heading {
  color: rgba(29, 80, 141, 1);
  font-family: Nunito sans;
  font-weight: bold;
}

.home-metric-cards {
  border: 2px solid rgb(253, 118, 137);
  z-index: 2;
  height: 7rem;
  border-radius: 20px;
  box-shadow: none;
  cursor: pointer;
  width: 100%;
  color: rgba(29, 80, 141, 1);
}

.cardsm {
  height: 7rem;
  border-radius: 20px;
  box-shadow: none;
}

.cardtitle {
  color: rgba(29, 80, 141, 1);
  font-family: 'Nunito Sans';
}

.activity {
  width: 20vw;
  height: 4vh;
  margin-right: 16px;
  padding: 0.8vh;
  text-align: center;
  border-radius: 6px;
  float: right;
}

@media screen and (max-width: 350px) {
  .smcardtitle {
    margin-left: -0.5rem;
  }

  #newimg {
    margin-right: -2px !important;
  }

  #cardtext {
    margin-left: -15px !important;
  }
}

#green:hover {
  box-shadow: 0px 24px 48px -18px rgba(22, 200, 141, 0.5);
}

#red:hover {
  box-shadow: 0px 24px 48px -18px rgba(253, 91, 113, 0.5);
}

ion-select::part(placeholder) {
  color: rgb(59, 59, 59);
  opacity: 0.8;
  font-size: smaller;
  font-family: Nunito Sans light, sans-serif;
}

.my-custom-interface .select-interface-option {
  color: rgba(29, 80, 141, 1);
  font-family: Nunito sans, sans-serif;
}

.colors {
  border-style: none;
  height: 55px;
  font-family: Nunito Sans light, sans-serif;
  font-size: large;
  color: white;
  border-radius: 10px;
  width: 100px;
  background-color: inherit;
}

.Daily {
  background-color: rgba(13, 87, 171, 1) !important;
}

.Weekly {
  background-color: rgb(12, 66, 128) !important;
}

.colors:active {
  color: rgb(145, 145, 140);
  opacity: 0.8;
}

.popovercont {
  display: flex;
  text-decoration: none;
  font-family: Nunito sans, sans serif;
}

#popover {
  --width: 10rem;
  --height: 8rem;
}

#popover .popover-content {
  right: 0rem;
}

ion-menu-button::part(native) {
  width: 20vw;
  height: 3rem;
}

#content {
  --background: radial-gradient(122.27% 198.92% at -22.27% -27.38%, #0070ba 0%, #1546a0 100%);
}

.bg {
  background: radial-gradient(122.27% 198.92% at -22.27% -27.38%, #0070ba 0%, #1546a0 100%) !important;
}

/* .round {
  position: relative;
}
.round label {
  background-color: #fff;
  border: 1px dashed #929bab;
  border-radius: 50%;
  cursor: pointer;
  height: 28px;
  left: 0;
  position: absolute;
  top: 0;
  width: 28px;
} 
.round label:after {
  border: 3px solid rgba(107, 201, 80, 1);
  border-top: none;
  border-right: none;
  content: '';
  height: 6px;
  left: 5px;
  opacity: 0;
  position: absolute;
  top: 7px;
  transform: rotate(-45deg);
  width: 12px;
} 
.round input[type='checkbox'] {
  visibility: hidden;
}
.round input[type='checkbox']:checked + label {
  background-color: #ffffff;
  border-color: #66bb6a;
}
.round input[type='checkbox']:checked + label:after {
  opacity: 1;
} */

.capitalize {
  text-transform: capitalize;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  width: 80vw;
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 102;
  opacity: 1 !important;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdownnotif {
  position: relative;
  display: inline-block;
}

.dropdownnotif-content {
  width: 80vw;
  display: none;
  position: absolute;
  right: 0;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: -5px 16px;
  z-index: 102;
  opacity: 1 !important;
}

.dropdownnotif:hover .dropdownnotif-content {
  display: block;
}

ion-fab-button::part(native) {
  background-color: #1d508d;
}

.zindex {
  z-index: 101;
}

ion-back-button::part(native) {
  color: white;
  margin-left: 6px;
}

ion-back-button::part(text) {
  margin-left: 6px;
}

.active {
  opacity: 1;
}

ion-card {
  border-radius: 20px;
  box-shadow: -1px 16px 48px 6px rgba(170, 175, 185, 0.1);
}

.form-title {
  text-align: center;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: #1d508d;
}

.basic-input {
  width: 100%;
  max-width: 25rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;

  /* inside input element */
  padding-left: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-right: 1rem;
  color: #0070ba;
  background: #ffffff;
  border: 2px solid #dbe6ec;
  box-sizing: border-box;
  border-radius: 20px;
}

.login-input {
  margin-left: auto;
  margin-right: auto;
}

.textarea {
  width: 100%;
  max-width: 25rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;

  /* inside input element */
  padding-left: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-right: 1rem;
  color: #0070ba;
  background: #ffffff;
  border: 2px solid #dbe6ec;
  box-sizing: border-box;
  border-radius: 20px;
}

.textarea::placeholder,
.basic-input::placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  /* Black */
  color: #243656;
  opacity: 0.5;
}

.date {
  width: 100%;
  max-width: 25rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;

  /* inside input element */
  padding-left: 1rem;
  padding-top: 0.3rem;
  padding-bottom: 0.1rem;
  padding-right: 1rem;
  margin-bottom: 1rem;
  color: #0070ba;
  background: #ffffff;
  border: 2px solid #dbe6ec;
  box-sizing: border-box;
  border-radius: 20px;
}

.date::placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  /* Black */
  color: #243656;
  opacity: 0.5;
}

.select {
  width: 80%;
  display: block;
  margin-left: 1.5rem;
  /* margin-right: auto; */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-left: 1rem;
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  padding-right: 0.5rem;
  margin-bottom: 1rem;
  background: #ffffff;
  border: 2px solid #dbe6ec;
  box-sizing: border-box;
  border-radius: 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  opacity: 0.5;
}

.select-container {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.custom-select {
  width: 100%;
  max-width: 25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-left: 1rem;
  padding-top: 0.3rem;
  padding-bottom: 0.1rem;
  padding-right: 1rem;
  margin-bottom: 1rem;
  background: #ffffff;
  border: 2px solid #dbe6ec;
  box-sizing: border-box;
  border-radius: 20px;
  font-size: 12px;
  opacity: 0.9;
}

.check-box {
  margin-bottom: 1.5rem;
}

.MuiInput-underline:before {
  border-bottom: 0px !important;
}

.MuiInput-underline:after {
  border-bottom: 0px !important;
  background: #ffffff !important;
}

.date:focus,
.select:focus,
.textarea:focus,
.basic-input:focus {
  outline: none;
  border: 1px solid #1d508d;
  border-radius: 20px;
}

.submit-button {
  width: 78%;
  padding: 1rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
  margin-left: auto;
  margin-right: auto;
  display: block;

  font-size: medium;
  background: radial-gradient(122.27% 198.92% at -22.27% -27.38%, #297cbb 0%, #1d508d 100%);
  box-shadow: 0px 24px 48px -18px rgba(21, 70, 160, 0.5);
  border-radius: 20px;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: #fff;
}

.submit-button:hover {
  background-color: #1e569b;
}

.submit-button:focus {
  box-shadow: #0070ba;
}

.scan-button {
  background: #ffffff;
  box-shadow: 1.75499px 7.01994px 42.1197px -14.0399px rgba(0, 0, 0, 0.1);
  border-radius: 17.5499px;

  margin-left: auto;
  margin-right: auto;
  display: block;
  padding-left: 1rem;
  padding-right: 1rem;
}

.scan-text {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #1d508d;
}

.fill-text {
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  /* Black */
  color: #243656;

  opacity: 0.5;
}

.side-menu-button {
  width: 1rem;
}

.side-menu-button {
  --border-radius: 0;
  --background-hover: none;
}

.component-action-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 2rem;
}

.component-button {
  padding-right: 1rem;
  padding-left: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem;
  border-radius: 17px;
  background-color: #fff;
  box-shadow: 1.75499px 7.01994px 42.1197px -14.0399px rgba(0, 0, 0, 0.1);
  border: 2px solid #dbe6ec;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #1d508d;
}

.input-container {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  width: 100%;
  max-width: 25rem;
  margin: auto;
  text-align: start;
}

.label {
  margin-left: 1rem;
  font-weight: bold;
  font-size: 12px;
  color: #243656;
  opacity: 0.5;
}

.checkbox {
  margin-left: 0.4rem;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  /* Black */
  opacity: 0.5;
}

option {
  font-family: Nunito Sans light, sans-serif;
}

.slider-icon {
  font-size: 1.6rem;
  margin-left: auto;
  margin-right: auto;
  align-self: center;
  text-align: center;
  display: block;
  color: white;
}

.slide-responsive-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.slider-item {
  margin: auto;
  display: block;
  text-align: center;
  padding-bottom: 2px;
  cursor: pointer;
  width: 98%;
  opacity: 0.5;
}

/* add hover effect of scale on menu click of .slider-item */
.slider-item:hover {
  transform: scale(1.1);
  transition: all 200ms ease-in-out;
  opacity: 0.8;
}

.bar-line-menu {
  background-color: white;
  height: 2rem;
  width: 2.5px;
  opacity: 0.6;
}

.active-slider-bg {
  opacity: 1;
}

.view-details-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0.3rem;
  border-bottom: 1px solid #1d508d;
  text-decoration: underline;
  font-size: 15px;
  color: #1d508d;
  cursor: pointer;
}

@media (min-width: 1025px) {
  .slide-responsive-container {
    display: none;
  }

  .responsive-card {
    display: flex;
    justify-content: space-around;
  }

  .activity {
    width: 50%;
    margin: auto;
    margin: 2rem;
  }

  .data-list {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }

  .model-card {
    display: block;
    width: 50%;
  }


  .submit-button {
    margin-top: 2rem;
    width: 261px;
  }
}

.edit-button {
  background-color: white;
  font-size: 12px;
  color: #1d508d;
  border: 1px solid #1d508d;
  box-shadow: 0px 24px 48px -18px rgba(21, 70, 160, 0.5);
  border-radius: 20px;
  padding-top: 7px;
  padding-bottom: 8px;
  display: flex;
  align-items: center;
}

/* Tailwind shortcuts */

.flex {
  display: flex;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.items-center {
  align-items: center;
}

.mx-2 {
  margin-left: 4px;
  margin-right: 4px;
}

.w-3 {
  width: 2.5rem;
}

.my-2 {
  margin-top: 2px;
  margin-bottom: 2px;
}

.pointer {
  cursor: pointer;
}

.img-center {
  margin: auto;
  display: block;
}

.pointer {
  cursor: pointer;
}

.text-center {
  text-align: center;
}

.no-rec-text {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #1d508d;
}

.no-rec-head {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 33px;
  /* identical to box height */

  text-align: center;

  color: #1d508d;
}

.return-home {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */

  color: #1d508d;
}

.underline-none {
  text-decoration: none;
}

.card-heading {
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: #1d508d;
}

.timeago {
  font-size: 10px;
}

/* Card text details sub heading */

.card-text-details {
  font-size: 12px;
}

.timeago,
.card-text-details {
  color: #929bab;
}

.view-details {
  font-weight: 300;
  font-size: 10px;
  color: #1d508d;
  cursor: pointer;
}

.gps-input {
  border: 0;
  /* input structure */
  /* inside input element */
  color: #0070ba;
  background: #ffffff;
}

.gps-input:focus {
  outline: none;
}

.gps-input::placeholder {
  font-size: 12px;
  /* Black */
  color: #243656;
  opacity: 0.5;
}

.gps-icon {
  width: 1.2rem;
  cursor: pointer;
}

.gps-icon:hover {
  opacity: 0.5;
}

.file-pond-custom {
  width: 80%;
  max-width: 25rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.preview-nav-title {
  font-weight: bolder;
  font-size: 1rem;
}

.preview-container {
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
}

.preview-header {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  align-items: center;
}

.preview-title {
  font-weight: 800;
  font-size: 20px;
  color: #1d508d;
  text-transform: capitalize;
}

.preview-title-subheading {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;

  color: #929bab;
}

.preview-key {
  font-weight: bolder;
  font-size: 14px;
  color: #919BAB;
}

.model-preview-key {
  font-size: 15px;
  padding: 0;
  color: #1d508d;
  margin-left: 1rem;
}

.preview-value {
  font-weight: 900 !important;
  font-size: 14px;
  text-align: right;
  color: #1d508d;
  max-width: 10rem;
}

.model-title {
  font-weight: 800;
  font-size: 17px;
  color: #1d508d;
  text-transform: capitalize;
  margin-left: 1rem;
  text-decoration: underline;
}

.model-preview-value {
  font-weight: 600 !important;
  font-size: 16px;
  color: #1d508d;
  margin-left: 1rem;
  border-radius: 7px;
  border: 2px solid rgb(229, 217, 217);
  background-color: white;
  padding: 0.5rem;
  text-transform: capitalize;
}


.collapsible {
  background-color: #dbe6ec;
  color: black;
  cursor: pointer;
  padding-left: 15px;
  padding-right: 15px;
  text-align: left;
  outline: none;
  font-size: 12px;
  max-width: 98%;
  margin: auto;
  margin-bottom: 1rem;
  box-shadow: 0px 24px 48px -18px rgba(15, 40, 87, 0.5);
}

.survey-title {
  font-weight: 800;
  font-size: 14px;
}

.label-survey {
  margin-left: 1rem;
  font-weight: bold;
  font-size: 15px;
  color: #243656;
  opacity: 0.7;
}

.preview-survey-img {
  width: 5rem;
  height: 5rem;
  border: 3px solid gray;
  border-radius: 1rem;
  box-shadow: 0px 24px 48px -18px rgba(15, 40, 87, 0.5);
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.preview-survey-img:hover {
  width: auto;
  height: auto;
  border: 0;
  margin: auto;
}

.close-icon {
  cursor: pointer;
  margin-left: 1rem;
}

.filter-container {
  width: 8rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  background-color: rgb(248 250 252);
}

.filter {
  border: 1px solid #dbe6ec;
  box-sizing: border-box;
  border-radius: 15px;
  display: flex;
  color: #929bab;
  font-size: 14px;
  max-width: 25rem;
  width: 8rem;
  margin-right: 0.5rem;
  margin-left: auto;
}

.date-filter {
  background: #ffffff;
  border: 2.33333px solid #f5f7fa;
  border-radius: 11.6667px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #1d508d;
  padding: 5px;
  padding: 3px;
  font-size: 10px;
}

.active-filter-row {
  display: flex;
  justify-content: flex-start;
  flex-flow: wrap;
  padding-left: 1rem;
}

.timeline-container {
  font-size: 15px;
  margin-left: 1rem;
  font-size: 12px;
  color: #243656;
  opacity: 0.7;
  display: flex;
  flex-flow: wrap;
  padding: 0.2rem;
}

.text-black {
  color: black;
}

.timeline-dot {
  background: black;
  height: 12px;
  width: 12px;
  border-radius: 5rem;
  border: 2.5px solid gray;
  margin-right: 0.4rem;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes pulse {

  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }
}

.energy-label {
  background: #16c98d;
  border-radius: 6px;
  color: white;
  text-align: center;
  padding-left: 8px;
  padding-right: 8px;
}

.view-detail-highight-icon {
  font-size: 1.6rem;
  margin: auto;
  display: block;
}

.tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
  width: 15px;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  position: absolute;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
  padding: 5px;
}

.tooltip .tooltiptext::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.qr-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #1d508d;
  font-size: 10px;
  padding: 10px;
}

.qr-code {
  text-decoration: underline;
  cursor: pointer;
}

.action-icon {
  font-size: 3.5vh;
  margin-left: 1.5rem;
  cursor: pointer;
  color: #1d508d;
}

.action-icon-map-modal {
  font-size: 1.5rem;
  margin-left: 1rem;
  cursor: pointer;
  color: #1d508d;
  margin-right: 1rem;
}

.action-icon-map-modal:hover {
  background-color: #f1f8f7;
  color: #102c4e;
  border-radius: 4px;
  transform: scale(1.25);
  transition-duration: 200ms;
}

.action-icon-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.qr-code-container {
  margin: auto;
  display: block;
}


.download-qr,
.print-qr {
  background: white;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border-radius: 7px;
  font-weight: 600;
  color: #1d508d;
  font-size: large;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-right: 10px;
  margin-left: 10px;
  margin-top: 4rem;
  display: flex;
  align-items: center;
}

/* iphone 5 */
@media only screen and (min-device-width: 560px) and (max-device-width: 1136px) and (-webkit-min-device-pixel-ratio: 2) {
  .preview-title {
    font-size: 10px;
  }
}

.checkList-val {
  font-weight: normal;
  font-size: 15px;
  text-align: left;
  color: #1d508d;
  margin-left: 2px;
  max-width: 12rem;
}

.label-checklist {
  margin-left: 5px;
  font-weight: bold;
  font-size: 15px;
  color: #243656;
  opacity: 0.5;
}

.printme {
  display: block;
}

@media print {
  .printme {
    display: block;
  }
}

table,
th,
td {
  border: 2px solid lightgray;
  padding: 3px;
}

th {
  font-weight: bolder;
  font-size: 15px;
  color: #243656;
  opacity: 0.7;
}

td {
  font-weight: bold;
  font-size: 15px;
  color: #243656;
  opacity: 0.5;
  text-align: 'center';
}

ion-alert:not(.select-alert) {
  --color: #243656 !important;
}

.color-box {
  border-radius: 3px;
  width: 25px;
  height: 20px;
  margin-left: 10px;
}

.underline {
  text-decoration: underline;
}

.full-screen-icon {
  position: absolute;
  width: 1.8rem;
  top: 0.7rem;
  z-index: 999999999;
  right: 1.2rem;
  background-color: white;
  padding: 1px;
  border-radius: 3px;
  cursor: pointer;
}

.gps-icon-map-small {
  position: absolute;
  z-index: 999999999;
  right: 1rem;
  top: 10.5rem;
  background-color: rgba(29, 80, 141, 1);
  color: white;
  padding: 7px;
  font-size: 25px;
  border-radius: 3rem;
  cursor: pointer;
}

.mapboxgl-ctrl-logo {
  display: none !important;
}

.gps-icon-map {
  position: absolute;
  font-size: 30px;
  right: 10px;
  background-color: rgba(29, 80, 141, 1);
  color: white;
  bottom: 2rem;
  border-radius: 3rem;
  padding: 15px;
  cursor: pointer;
  box-shadow: 0px -2px 52px 3px rgba(0, 0, 0, 0.84);
  -webkit-box-shadow: 0px -2px 52px 3px rgba(0, 0, 0, 0.84);
  -moz-box-shadow: 0px -2px 52px 3px rgba(0, 0, 0, 0.84);
}

.asset-icon-map {
  position: absolute;
  font-size: 20px;
  left: 10px;
  background-color: rgba(29, 80, 141, 1);
  color: white;
  bottom: 2rem;
  border-radius: 3rem;
  padding: 20px;
  cursor: pointer;
  box-shadow: 0px -2px 52px 3px rgba(0, 0, 0, 0.84);
  -webkit-box-shadow: 0px -2px 52px 3px rgba(0, 0, 0, 0.84);
  -moz-box-shadow: 0px -2px 52px 3px rgba(0, 0, 0, 0.84);
}


.map-plotly {
  height: 2rem;
  display: block;
  height: 40px;
}

.mapboxgl-map {
  height: 100% !important;
}

.back-map-button {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 1rem;
  left: 1rem;
  background-color: rgba(29, 80, 141, 1);
  color: white;
  border-radius: 6rem;
  padding-top: 6px;
  cursor: pointer;
  z-index: 999999999;
  font-size: 22px;
  box-shadow: 0px -2px 52px 3px rgba(0, 0, 0, 0.84);
  -webkit-box-shadow: 0px -2px 52px 3px rgba(0, 0, 0, 0.84);
  -moz-box-shadow: 0px -2px 52px 3px rgba(0, 0, 0, 0.84);
}

.mapboxgl-ctrl-bottom-right {
  display: none;
}

.active-map-button {
  border: 2px solid white;
}

#static {
  text-align: center;
  font-size: 1.5rem;
  position: absolute;
  z-index: 1;
  top: 20%;
  left: 40%;
}

.edit-icon-photo {
  font-size: 1.8rem;
  position: absolute;
  bottom: 10px;
  right: 1rem;
  border-radius: 99px;
  padding: 3px;
  cursor: pointer;
  color: darkgray;
  background-color: lightgray;
  color: white;
  padding: 8px;
}

.edit-icon-photo:hover {
  background-color: darkgray;
}

.overdue {
  border: 1px solid red;
  border-radius: 12px;
  text-align: center;
  width: 5rem;
  color: red;
  font-weight: 700;
  margin-right: auto;
  margin-top: 5px;
  opacity: 0.5;
}

.legends-on-map-containter {
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 999999999;
  background: white;
  opacity: 0.85;
  border-radius: 12px;
  font-size: 12px;
  padding-left: 4px;
  padding-right: 6px;
}

.legends-on-map-item {
  height: 15px;
  width: 15px;
  border-radius: 999px;
  margin-right: 5px;
  opacity: 1.0;
}

.filter-on-map {
  position: absolute;
  top: 5rem;
  left: 1rem;
  z-index: 999999999;
  background: white;
  opacity: 0.85;
  border-radius: 12px;
  font-size: 12px;
  padding-left: 4px;
  padding-right: 6px;
}

.asset-modal-layout {
  position: absolute;
  border: 5px solid white;
  bottom: 0rem;
  right: 0%;
  left: 0%;
  z-index: 999999999;
  background: white;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  font-size: 12px;
  padding-left: 10px;
  padding-right: 10px;
  max-height: 40rem;
  overflow-y: scroll;
  padding-top: 1.2rem;
}

.tooltip-off {
  pointer-events: none;
}

.street-icon {
  position: absolute;
  width: 1.5rem;
  z-index: 999999999;
  right: 0.7rem;
  background-color: white;
  padding: 1px;
  border-radius: 3px;
  cursor: pointer;
  top: 0.6rem;
}

/* .street-icon-on-full {
  z-index: 999999999;
  position: absolute;
  right: 20px;
  background-color: white;
  bottom: 8rem;
  width: 3rem;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 0px -2px 52px 3px rgba(0, 0, 0, 0.84);
  -webkit-box-shadow: 0px -2px 52px 3px rgba(0, 0, 0, 0.84);
  -moz-box-shadow: 0px -2px 52px 3px rgba(0, 0, 0, 0.84);
} */

input[type=radio] {
  -ms-transform: scale(2.5);
  /* IE 9 */
  -webkit-transform: scale(2.5);
  /* Chrome, Safari, Opera */
  transform: scale(2.5);
}

.pulse {
  display: block;
  width: 20px;
  height: 20px;
  margin-top: -20px;
  margin-left: 14px;
  border-radius: 50%;
  background: rgba(203, 228, 255, 1);

  cursor: pointer;
  box-shadow: 0 0 0 rgba(204, 169, 44, 0.4);
  animation: pulse 2s infinite;
}

.pulse:hover {
  animation: none;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
  }

  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
  }

  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
  }

  70% {
    -moz-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
  }

  100% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}

.fly-to-button-full-screen {
  position: absolute;
  width: 29px;
  z-index: 999999999;
  left: 0.65rem;
  top: 10rem;
  padding: 3px;
  background-color: white;
  border-radius: 4px;
  height: 29px;
  cursor: pointer;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, .1);
}

.checklist-title {
  font-weight: 900 !important;
  font-size: 14px;
  color: #1d508d;
  margin: 2px;
}

.checklist-remark {
  font-weight: bolder;
  font-size: 14px;
  color: #919BAB;
  margin: 2px;
}

.round {
  position: relative;
}

.round label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 28px;
  left: 0;
  position: absolute;
  top: 0;
  width: 28px;
}

.round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 7px;
  opacity: 0;
  position: absolute;
  top: 8px;
  transform: rotate(-45deg);
  width: 12px;
}

.round input[type="checkbox"] {
  visibility: hidden;
}

.round input[type="checkbox"]:checked+label {
  background-color: #66bb6a;
  border-color: #66bb6a;
}

.round input[type="checkbox"]:checked+label:after {
  opacity: 1;
}

.model-card {
  display: block;
  border-radius: 4px;
  color: black;
  background-color: rgba(236, 224, 224, 0.35);
  padding-top: 1px;
  padding-bottom: 1px;
  margin-left: 1rem;
  margin-right: 1rem;
  padding-right: 1rem;
}


/* Modal only for full screen map */

.mapModalFullScreen {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  padding: 0.5rem;
  border-radius: 8px;
  box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
  border: 2px solid #cfcfd9;
  z-index: 999999999;
  width: 50%;
  color: #000000;
  display: block;
}

.mapOptionsTitle {
  display: flex;
  font-weight: 600;
  color: #1d508d;
  justify-content: space-between;
  margin: 0.5rem;
  border-bottom: 1px solid #e8e8eeb6;
  font-size: 15px;
  cursor: pointer;
}

.optionMapClickable {
  cursor: pointer;
  font-weight: 800;
  color: #1d508d;
  border: 1px solid #1d508d;
  border-radius: 17px;
  padding: 5px 8px 5px 8px;
}

.toastMapModal {
  position: fixed;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #1d508d;
  padding: 0.3rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 6px;
  box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
  color: white;
}

.radioMapModal {
  display: flex;
  justify-content: space-between;
  margin: 0.7rem;
  font-size: 15px;
  width: 10px;
  cursor: pointer;

  /* identical to box height */
  color: #1d508d;
}

/* add on focus add color */
.radioMapModal:focus {
  outline: none;
  border: 1px solid #1d508d;
  border-radius: 17px;
  padding: 5px 8px 5px 8px;
}

.custom-ion-select {
  width: 100%;
  max-width: 25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-left: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-right: 1rem;
  margin-bottom: 1rem;
  background: #ffffff;
  border: 2px solid #dbe6ec;
  box-sizing: border-box;
  border-radius: 20px;
  font-size: 12px;
  opacity: 0.9;
}

.preview-asset-component {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  border-bottom: 1px solid #e8e8eeb6;
  font-size: 15px;
  color: #1d508d;
}

.modal-popover-profile {
  background-color: white;
  position: absolute;
  top: 3.2rem;
  right: 1.5rem;
  border-radius: 4px;
  padding: 8px;
  z-index: 60;
}

.list-items-popover {
  display: block;
  width: 10rem;
}

.list-items-popover:hover {
  background-color: #e8e8eeb6;
}

.pagination-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 1rem;
}

.pagination-button {
  background-color: #1d508d;
  color: white;
  border: none;
  border-radius: 6px;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  cursor: pointer;
  font-size: 1.4rem;
  padding-top: 8px;
  padding-bottom: 4px;
}

.disabled-pagination-button {
  background-color: #1d508d;
  color: white;
  border: none;
  border-radius: 6px;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  cursor: not-allowed;
  font-size: 1.4rem;
  padding-top: 8px;
  padding-bottom: 4px;
  opacity: 0.5;
}

/* **************************************************************************************************************************** */
/* **************************************************************************************************************************** */
/* CSS MEDIA QUERY */
/* **************************************************************************************************************************** */
/* **************************************************************************************************************************** */

/* Mobile Styles */
@media only screen and (max-width: 767px) {

  /* Your mobile-specific CSS here */
  .home-metric-grid {
    padding: 1rem;
  }

  .home-metric-title {
    white-space: nowrap;
    font-size: 12.5px;
  }

  .metric-container {
    padding: 1rem;
  }
}

/* Tablet Styles */
@media only screen and (min-width: 768px) and (max-width: 1023px) {

  /* Your tablet-specific CSS here */
  .home-metric-grid {
    width: 70%;
  }

  .home-metric-title {
    white-space: nowrap;
    font-size: 16px;
  }

  .metric-container {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}


/* Desktop Styles */
@media only screen and (min-width: 1024px) {

  /* Your desktop-specific CSS here */
  .home-metric-grid {
    width: 50%;
  }

  .home-metric-title {
    white-space: nowrap;
    font-size: 18px;
  }

  .metric-container {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}